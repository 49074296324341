<template>
  <v-container fluid class="pt-0">
    <v-breadcrumbs class="pa-0 mb-2" :items="[{ text: $t('main.back') }]">
      <template #item="{ item }">
        <v-breadcrumbs-item class="global-cursor-pointer" @click="backToRoute()">
          <v-icon color="black" size="15">
            $arrow-left
          </v-icon>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <header-panel
      :campaign="campaign"
      :is-loading="isLoading"
      @update="fetchCampaign()"
    />

    <v-alert v-if="hasLimitStatus" color="warning" class="text-center white--text mb-0 mt-4">
      <v-icon size="16" color="white">
        $blocked
      </v-icon>
      {{ limitStatus }}
    </v-alert>

    <v-row no-gutters>
      <v-col cols="12" lg="8">
        <v-row class="mt-4 px-md-3">
          <v-row :class="['width-100 ma-0 flex-column flex-md-row mb-md-3 flex-lg-column', { 'fill-height': !isMdOnly }]">
            <v-col md="3" lg="12" class="pa-0 px-md-0 pr-md-1 pr-lg-0 mb-lg-5">
              <v-col v-if="!campaignIsArchived && !isLgAndUp" cols="12" md="12" class="py-0 mb-2 px-md-0 mb-md-1">
                <new-campaign-actions
                  :campaign="campaign"
                  @update="fetchCampaign()"
                />
              </v-col>
              <v-col v-show="campaignId && campaign.pricingModel" cols="12" lg="12" class="px-md-0 mb-md-n1">
                <campaign-info
                  class="mx-n1"
                  :campaign="campaign"
                  :campaign-statistics="campaignStatistics"
                  :statistics-is-loading="statisticsIsLoading"
                />
              </v-col>
            </v-col>
            <v-col cols="12" md="9" lg="12" class="fill-height py-0 mt-3 mt-sm-2 px-md-0 pl-md-1 mt-md-0 pl-lg-0">
              <statistics
                :campaign-id="campaignId"
                :campaign-statistics="campaignStatistics"
                :statistics-dates="statisticsDates"
                :statistics-is-loading="statisticsIsLoading"
                :is-disabled="!campaign.statisticGatheringDone"
                @update-dates="updateStatistics($event)"
              />
            </v-col>
          </v-row>
          <v-col cols="12" class="py-0 my-3 px-md-0 mt-md-0">
            <rules-panel :is-loading="isLoading" :campaign-id="campaignId" :campaign="campaign" @fetch-rules="fetchCampaign()" />
          </v-col>
          <v-col cols="12" class="py-0 mt-3 px-md-0 mt-md-0">
            <price-panel :campaign="campaign" :is-loading="isLoading" @update-campaign="fetchCampaign()" />
          </v-col>
          <v-container fluid class="pb-0 px-md-0">
            <v-row class="width-100 mx-0 my-0 d-block px-md-0 d-md-flex flex-md-row-reverse">
              <v-col v-if="!isLgAndUp" cols="12" md="4" class="pa-0 fill-md-height mt-md-0 pl-md-0">
                <general-panel :is-loading="isLoading" :campaign="campaign" />
              </v-col>
              <v-col cols="12" md="8" lg="12" class="pa-0 mt-3 pr-md-3 pr-lg-0 fill-md-height mt-md-0">
                <targets-panel class="fill-height px-4 py-5" :campaign="campaign" />
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid class="mt-0 px-3 px-md-0">
            <v-row class="ma-md-0">
              <v-col v-if="!isLgAndUp" cols="12" md="4" class="pa-md-0 order-md-3">
                <ad-card :ad="campaign.ad" />
              </v-col>
              <v-col cols="12" md="8" lg="12" class="py-0 pa-md-0 pr-md-3 pr-lg-0">
                <v-col class="pa-0 px-md-0 mt-lg-0">
                  <div class="pa-5 white rounded">
                    <calendar-limits-select
                      :value="campaign.calendarLimits"
                      :min-limit="minLimit"
                      :limit-type="campaign.limitType"
                      :calendar-help-url="helpUrls.campaign_limits"
                      read-only
                    />
                  </div>
                </v-col>
                <v-col v-if="!isLgAndUp" cols="12" lg="4" md="12" class="pa-0 mt-3 px-md-0">
                  <limits-panel :is-loading="isLoading" :campaign="campaign" />
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
      <v-col v-if="isLgAndUp" class="mt-4" lg="4">
        <v-col v-if="!campaignIsArchived" cols="12" class="py-0 pr-0 mb-3">
          <new-campaign-actions
            :campaign="campaign"
            @update="fetchCampaign()"
          />
        </v-col>
        <v-col cols="12" class="py-0 pr-0 mb-3">
          <general-panel :is-loading="isLoading" :campaign="campaign" />
        </v-col>
        <v-col lg="12" class="py-0 pr-0 mb-3">
          <ad-card :ad="campaign.ad" />
        </v-col>
        <v-col cols="12" class="py-0 pr-0">
          <limits-panel :is-loading="isLoading" :campaign="campaign" />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { mapGetters, mapState } from 'vuex'
  import { CalendarLimitsSelect } from '@clickadilla/components/CalendarLimitsSelect/index.js'
  import alertsTypes from '@clickadilla/components/constants/campings-alerts-types.js'
  import handleErrors from '@/services/handleErrors.js'
  import campaignsRepository from '@/services/repositories/campaigns-repository.js'
  import { campaignsStatisticsRepository } from '@/services/repository-factory.js'
  import Campaign from '@/services/classes/Campaign.js'
  import TargetsPanel from '@/views/Campaign/TargetsPanel/Index.vue'
  import Statistics from '@/views/Campaign/Statistics/Index.vue'
  import routeNames from '@/types/route-names.js'
  import limitTypes from '@/types/limit-types.js'
  import GeneralPanel from '@/views/Campaign/GeneralPanel/Index.vue'
  import HeaderPanel from '@/views/Campaign/HeaderPanel/Index.vue'
  import PricePanel from '@/views/Campaign/PricePanel/Index.vue'
  import LimitsPanel from './LimitsPanel.vue'
  import NewCampaignActions from '@/views/Campaign/NewCampaignActions.vue'
  import CampaignInfo from '@/views/Campaign/CampaignInfo.vue'
  import AdCard from '@/components/ads/AdCard/Index.vue'
  import RulesPanel from '@/views/NewCampaign/RulesPanel/index.vue'

  export default {
    name: 'Campaign',
    components: {
      RulesPanel,
      AdCard,
      NewCampaignActions,
      CampaignInfo,
      LimitsPanel,
      HeaderPanel,
      PricePanel,
      TargetsPanel,
      Statistics,
      GeneralPanel,
      CalendarLimitsSelect
    },
    data() {
      return {
        routeNames,
        isLoading: false,
        statisticsIsLoading: false,
        campaign: new Campaign(),
        statisticsDates: {
          start: moment().add({ days: -7 }).format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD')
        },
        campaignStatistics: [],
        alertType: alertsTypes.BEFORE_EDIT,
        gscCampaignWidgetIsShown: false
      }
    },
    computed: {
      ...mapState('settings', [
        'minLimitInMoney',
        'minLimitInClicks',
        'minLimitInImpressions',
        'adNetwork'
      ]),
      ...mapGetters('settings', ['helpUrls']),
      campaignIsArchived() {
        return this.campaign.status === 'archived'
      },
      isLgAndUp() {
        return this.$vuetify.breakpoint.lgAndUp
      },
      isMdOnly() {
        return this.$vuetify.breakpoint.mdOnly
      },
      campaignId() {
        return +this.$route.params.id
      },
      minLimit() {
        return {
          [limitTypes.IMPRESSIONS]: this.minLimitInImpressions,
          [limitTypes.CLICKS]: this.minLimitInClicks,
          [limitTypes.MONEY]: this.minLimitInMoney
        }[this.campaign.limitType]
      },
      gscAndCampaignIsLoaded() {
        const isActiveTo = this.campaign.activeTo && Date.parse(this.campaign.activeTo) < Date.now()
        const targetsCampaign = this.campaign.limitExceededName === 'total' || this.campaign.status === 'paused' || isActiveTo
        return this.$gsc.inited && !this.isLoading && this.campaign.statisticGatheringDone && this.gscCampaignWidgetIsShown && targetsCampaign
      },
      hasLimitStatus() {
        return !!this.campaign.limitExceededName
      },
      limitStatus() {
        if (!this.hasLimitStatus) {
          return ''
        }
        return this.$t(`main.limit_statuses.${this.campaign.limitExceededName}`)
      }
    },
    watch: {
      gscAndCampaignIsLoaded: {
        immediate: true,
        handler(value) {
          if (value) {
            this.$gsc.openWidget(this.adNetwork.settings.displayPopup.getsitecontrol.campaign_widget_id)
            this.$gsc.setParams({ campaign_id: this.id })
            localStorage.gscCampaignIds = JSON.stringify([
              ...JSON.parse(localStorage.gscCampaignIds),
              Number(this.id)
            ])
          }
        }
      }
    },
    created() {
      this.fetchCampaign()
      this.fetchStatistics()

      if (!localStorage.gscCampaignIds) {
        localStorage.gscCampaignIds = JSON.stringify([])
      }
      const gscCampaignIds = JSON.parse(localStorage.gscCampaignIds)
      const campaignWidgetId = this.adNetwork.settings.displayPopup.getsitecontrol.campaign_widget_id
      this.gscCampaignWidgetIsShown = campaignWidgetId && !gscCampaignIds.includes(Number(this.id))
    },
    beforeDestroy() {
      if (this.gscAndCampaignIsLoaded) {
        this.$gsc.closeWidget(this.adNetwork.settings.displayPopup.getsitecontrol.campaign_widget_id)
      }
    },
    methods: {
      updateStatistics(selectedDates) {
        this.statisticsDates = selectedDates
        this.fetchStatistics()
      },
      async fetchCampaign() {
        this.isLoading = true
        try {
          const response = await campaignsRepository.showWithoutModel(this.campaignId)
          this.campaign = new Campaign(response)
        } catch (error) {
          await this.$router.push({ name: routeNames.CAMPAIGNS })
        }
        this.isLoading = false
      },
      async fetchStatistics() {
        this.statisticsIsLoading = true
        try {
          this.campaignStatistics = await campaignsStatisticsRepository.list({
            selectedCampaigns: [this.campaignId],
            dates: this.statisticsDates,
            groupBy: 'date'
          })
        } catch (error) {
          handleErrors(error)
        }
        this.statisticsIsLoading = false
      },
      async backToRoute() {
        if (this.backRouteName) {
          this.$router.go(-1)
        }
        await this.$router.push({ name: routeNames.CAMPAIGNS })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .width-100 {
    width: 100%;
  }
</style>
