<template>
  <div class="d-flex ads-actions">
    <div
      v-for="settingsAction in settingsActions"
      :key="settingsAction.value"
      class="action"
    >
      <table-action
        x-small
        :settings-action="settingsAction"
        @editable-handler="openEditForm()"
        @statistics-handler="openAdStatistics()"
        @duplicable-handler="openDuplicateForm()"
        @deletable-handler="dialogIsShown = true"
      />
    </div>
    <ads-confirm-dialog
      :is-shown="dialogIsShown"
      :title="$t('main.ad_form.archive_ad', { name: ad.name })"
      :body="$t('main.ad_form.archive_ad_title', { name: ad.name })"
      @close-dialog="dialogIsShown = false"
      @remove-ad="removeAd()"
    />
  </div>
</template>

<script>
  import TableAction from '@clickadilla/components/ui/TableAction.vue'
  import { mapGetters } from 'vuex'
  import AdsConfirmDialog from '@/components/ads/AdsConfirmDialog.vue'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import Ads from '@/services/classes/Ads.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'AdsActions',
    components: {
      AdsConfirmDialog,
      TableAction
    },
    props: {
      ad: {
        type: Ads,
        required: true
      }
    },
    data() {
      return {
        dialogIsShown: false,
        removeIsLoading: false
      }
    },
    computed: {
      ...mapGetters('settings', ['getAdFormatsByAdNetwork']),
      settingsActions() {
        return [
          {
            value: 'editable',
            icon: '$pencil',
            text: this.$t('main.ad_form.action_edit', { name: this.ad.name }),
            color: 'primary',
            method: 'editable-handler',
            size: 12,
            disabled: this.ad.actions.editable
          },
          {
            value: 'statistics',
            icon: '$chart-line',
            text: this.$t('main.ad_form.action_statistics', { name: this.ad.name }),
            color: 'info',
            method: 'statistics-handler',
            size: 12,
            disabled: this.ad.actions.editable
          },
          {
            value: 'duplicable',
            icon: '$copy',
            text: this.$t('main.ad_form.duplicate', { name: this.ad.name }),
            color: 'black',
            method: 'duplicable-handler',
            size: 12,
            disabled: this.ad.actions.duplicable
          },
          {
            value: 'deletable',
            icon: '$trash',
            text: this.$t('main.ad_form.action_delete', { name: this.ad.name }),
            color: 'error',
            method: 'deletable-handler',
            isLoading: this.removeIsLoading,
            size: 12,
            disabled: this.ad.actions.deletable
          }
        ]
      },
      adFormatIds() {
        return this.getAdFormatsByAdNetwork.reduce((acc, adFormat) => {
          if (adFormat.type === this.ad.adFormatType && adFormat.size === this.ad.size) {
            acc.push(adFormat.id)
          }
          return acc
        }, [])
      }
    },
    methods: {
      async openEditForm() {
        await this.$router.push({ name: routeNames.EDIT_AD, params: { id: this.ad.id } })
      },
      async openDuplicateForm() {
        await this.$router.push({ name: routeNames.DUPLICATE_AD, params: { id: this.ad.id } })
      },
      async openAdStatistics() {
        const adFormatIds = JSON.stringify(this.adFormatIds)
        const currentAds = await adsRepository.show(this.ad.id)
        const campaignsIds = JSON.stringify(currentAds.campaigns.map(({ id }) => id))

        await this.$router.push({
          name: routeNames.CAMPAIGN_STATISTICS,
          query: {
            campaigns: campaignsIds,
            groupBy: 'creative',
            adFormatIds
          }
        })
      },
      async removeAd() {
        this.removeIsLoading = true
        try {
          await adsRepository.delete(this.ad.id)
          this.$emit('removed')
          this.$showSuccessNotification(this.$t('main.ad_form.archived_ad', { name: this.ad.name }))
        } catch (error) {
          this.$showErrorNotification(this.$t('main.ad_form.error_archiving', { name: this.ad.name }))
        }
        this.removeIsLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.action:not(:first-of-type){
  position: relative;

  &:after{
    position: absolute;
    content: "";
    width: 1px;
    height: 14px;
    left: -4px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--v-secondary-base) !important;
  }
}

.ads-actions{
  gap: 8px !important;
}
</style>
