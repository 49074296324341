<template>
  <div class="d-flex align-center mb-1">
    <p class="warning--text text-caption custom-title mb-0">
      {{ $t('campaign.rule_suspended.title') }}
    </p>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon class="ml-1" v-bind="attrs" size="12" color="info" v-on="on">
          $information
        </v-icon>
      </template>
      {{ $t('campaign.rule_suspended.description') }}
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'RulesPanelNotification'
  }
</script>

<style lang="scss" scoped>
  .custom-title {
    line-height: 12px !important;
  }
</style>
