<template>
  <div class="border-primary rounded py-6 py-sm-3">
    <confirm-dialog ref="archiveAcceptDialog" @confirm="archiveCampaign()">
      <template #title>
        {{ $t('campaign.archive_campaign') }}
      </template>
      <template #body>
        {{ $t('campaign.sure_archive_campaign') }}
      </template>
    </confirm-dialog>
    <v-layout class="justify-center">
      <div
        v-for="(actionItem, index) in actions"
        :key="index"
        :class="[
          'd-flex flex-column align-center',
          { 'pl-5 pl-sm-15 pl-md-2 pl-lg-5': index !== 0 }
        ]"
      >
        <table-action
          :settings-action="actionItem"
          icon
          @edit="beforeEdit()"
          @duplicate="duplicate()"
          @activate="beforeActivate()"
          @pause="pause()"
          @archive="showArchiveModal()"
          @download="fetchFlatDealDocument()"
          @update="update()"
        />
        <div
          v-if="!isSmOrMdSizes"
          class="secondary-darken--text text-caption mt-2 mt-lg-1"
        >
          {{ actionItem.text }}
        </div>
      </div>
    </v-layout>
    <campaign-alert ref="campingAlert" :alert-type="alertType" @confirm="onDialogConfirm()" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import TableAction from '@clickadilla/components/ui/TableAction.vue'
  import alertsTypes from '@clickadilla/components/constants/campings-alerts-types.js'
  import { GTM_EVENTS, GTM_EVENTS_CATEGORY } from '@clickadilla/components/constants/gtm-events.js'
  import { pricingModelTypes } from '@clickadilla/components/constants/pricing-model-types.js'
  import CampaignAlert from '@clickadilla/components/modals/CampaignAlert.vue'
  import routeNames from '@/types/route-names.js'
  import Campaign from '@/services/classes/Campaign.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import campaignsRepository from '@/services/repositories/campaigns-repository.js'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'
  import { flatDealsRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'

  const { CAMPAIGN_ACTIONS } = GTM_EVENTS_CATEGORY
  const {
    CAMPAIGN_ACTION_ACTIVATE,
    CAMPAIGN_ACTION_ARCHIVE,
    CAMPAIGN_ACTION_EDIT,
    CAMPAIGN_ACTION_PAUSE,
    CAMPAIGN_ACTION_DUPLICATE,
    CAMPAIGN_ACTION_IN_PROCESSING
  } = GTM_EVENTS

  export default {
    name: 'CampaignActions',
    components: {
      ConfirmDialog,
      CampaignAlert,
      TableAction
    },
    props: {
      campaign: {
        type: Campaign,
        required: true
      }
    },
    data() {
      return {
        alertType: alertsTypes.BEFORE_EDIT,
        alertsTypes
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      isSmOrMdSizes() {
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md
      },
      actions() {
        return [
          {
            value: 'download',
            color: 'primary',
            method: 'download',
            icon: 'mdi-download',
            text: this.$t('main.campaign_actions.download_io'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: true,
            isShown: !!this.campaign.flatDealApplication?.id
          },
          {
            value: 'activable',
            color: 'primary',
            method: 'activate',
            icon: 'mdi-play',
            text: this.$t('campaign.activate'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: !!this.campaign.actions.activable,
            isShown: !!this.campaign.actions.activable
          },
          {
            value: 'pausable',
            color: 'warning',
            method: 'pause',
            icon: '$pause',
            text: this.$t('campaign.pause'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: !!this.campaign.actions.pausable,
            isShown: !!this.campaign.actions.pausable
          },
          {
            value: 'editable',
            color: 'primary',
            method: 'edit',
            icon: '$pencil',
            text: this.$t('campaign.edit'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: !!this.campaign.actions.editable,
            isShown: !!this.campaign.actions.editable
          },
          {
            value: 'duplicable',
            color: 'black',
            method: 'duplicate',
            icon: '$copy',
            text: this.$t('campaign.duplicate'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: !!this.campaign.actions.duplicable,
            isShown: !!this.campaign.actions.duplicable
          },
          {
            value: 'deletable',
            color: 'error',
            method: 'archive',
            icon: '$trash',
            text: this.$t('campaign.archive'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: !!this.campaign.actions.deletable,
            isShown: !!this.campaign.actions.deletable
          },
          {
            value: 'update',
            color: 'info',
            method: 'update',
            icon: '$update',
            text: this.$t('main.campaign_actions.in_processing'),
            tooltipIsDisabled: this.isSmOrMdSizes,
            disabled: true,
            isShown: !!this.campaign.nextStatus
          }
        ].filter((action) => action.isShown !== false)
      },
      campaignAlertIsShown() {
        return [pricingModelTypes.CPM_GOAL, pricingModelTypes.CPC_GOAL].includes(
          this.campaign.pricingModel
        )
      },
      isTestingStatus() {
        return this.campaign.cpaStatus === 'testing'
      },
      archiveWidgetId() {
        const {
          archive_campaign_ru_widget_id: widgetIdRus,
          archive_campaign_en_widget_id: widgetIdEn
        } = this.adNetwork.settings.displayPopup.getsitecontrol
        return (this.locale !== 'ru' && widgetIdRus) ? widgetIdEn : widgetIdRus
      }
    },
    methods: {
      async action(actionType) {
        this.isLoading = true
        try {
          await campaignsRepository[actionType]({ ids: [this.campaign.id] })
        } catch (error) {
          handleErrors(error)
          this.$showErrorNotification(error.message)
        }
        this.isLoading = false
        this.$emit('update')
      },
      setAlertTypeAndOpenGoalDialog(type) {
        this.alertType = type
        this.$refs.campingAlert.open()
      },
      onDialogConfirm() {
        if (this.alertType === alertsTypes.BEFORE_EDIT) {
          this.edit()
        }

        if (this.alertType === alertsTypes.BEFORE_START) {
          this.action('activate')
        }
      },
      beforeEdit() {
        gtmPush({
          event: CAMPAIGN_ACTION_EDIT,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })

        if (this.campaignAlertIsShown && this.isTestingStatus) {
          this.setAlertTypeAndOpenGoalDialog(alertsTypes.BEFORE_EDIT)
          return
        }

        this.edit()
      },
      edit() {
        this.$router.push({
          name: routeNames.EDIT_CAMPAIGN,
          params: { id: this.campaign.id }
        })
      },
      duplicate() {
        gtmPush({
          event: CAMPAIGN_ACTION_DUPLICATE,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })

        this.$router.push({
          name: routeNames.DUPLICATE_CAMPAIGN,
          params: { id: this.campaign.id }
        })
      },
      beforeActivate() {
        gtmPush({
          event: CAMPAIGN_ACTION_ACTIVATE,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })
        if (this.campaignAlertIsShown) {
          this.setAlertTypeAndOpenGoalDialog(alertsTypes.BEFORE_START)
          return
        }

        this.action('activate')
      },
      pause() {
        gtmPush({
          event: CAMPAIGN_ACTION_PAUSE,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })
        this.action('pause')
      },
      showArchiveModal() {
        gtmPush({
          event: CAMPAIGN_ACTION_ARCHIVE,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })
        this.$refs.archiveAcceptDialog.ask()
      },
      archiveCampaign() {
        if (this.archiveWidgetId) {
          this.$gsc.setParams({ post_campaign_id: this.campaign.id })
          this.$gsc.openWidget(this.archiveWidgetId)
          this.action('delete')
        }
      },
      async fetchFlatDealDocument() {
        const applicationId = this.campaign.flatDealApplication.id
        try {
          await flatDealsRepository.document(applicationId)
        } catch (error) {
          handleErrors(error)
        }
      },
      update() {
        gtmPush({
          event: CAMPAIGN_ACTION_IN_PROCESSING,
          event_category: CAMPAIGN_ACTIONS,
          user_id: this.$auth.user?.id
        })
        this.$emit('update')
      }
    }
  }
</script>
