<template>
  <v-card elevation="0" class="pa-5">
    <v-card-title class="d-flex pa-0 text-h6 font-weight-medium black--text">
      {{ $t('campaign.rules.rules') }}
      <v-chip
        class="ml-2 px-2"
        color="primary"
        label
        small
        text-color="white"
      >
        <span class="text-uppercase font-weight-bold text-caption">New</span>
      </v-chip>
    </v-card-title>
    <v-card-text class="pa-0 mt-4">
      <v-skeleton-loader :loading="isLoading || rulesAreLoading" type="table-heading, table-heading">
        <v-container class="ma-0 pa-0" fluid>
          <v-row v-if="rules.length" no-gutters class="flex-column">
            <v-col
              v-for="(rule, idx) in rules"
              :key="idx"
              cols="12"
              :class="[
                'py-5 pb-4',
                { 'pt-3 pb-4 separator-item': idx !== 0 },
                { 'pt-0': idx === 0 }
              ]"
            >
              <rule-item
                :rule-options="rulesDefaultOptions"
                :disabled="(isEditing && currentRule.id !== rule.id) || isCreating"
                :editing="isEditing && currentRule.id === rule.id"
                :current-rule="rule"
                :errors="errors"
                :campaign-targets="campaign.targets"
                @delete="deleteRule($event)"
                @update="updateCurrentRule($event)"
                @clear-error="clearError($event)"
                @save="save()"
                @cancel="cancel()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isCreating" no-gutters>
            <v-col cols="12" :class="{ 'pt-3 separator-item': isCreating && rules.length }">
              <rule-item
                :errors="errors"
                :rule-options="rulesDefaultOptions"
                :editing="isCreating"
                :campaign-targets="campaign.targets"
                :current-rule="currentRule"
                @clear-error="clearError($event)"
                @save="save()"
                @cancel="cancel()"
              />
            </v-col>
          </v-row>
          <v-row v-if="errorMessages">
            <errors display-all :error-messages="errorMessages" />
          </v-row>
          <v-row v-if="canAddingRule && !isCreating && !isEditing" justify="end" no-gutters class="mt-5">
            <v-col cols="auto">
              <c-btn
                color="primary"
                :height="32"
                small
                class="px-3"
                :min-width="58"
                outlined
                depressed
                :label="$t('campaign.rules.add_rule')"
                @click="addRule()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import RuleItem from '@/views/NewCampaign/RulesPanel/RuleItem.vue'
  import handleErrors from '@/services/handleErrors.js'
  import Rule from '@/services/classes/Rule.js'
  import { campaignsRepository } from '@/services/repository-factory.js'
  import Campaign from '@/services/classes/Campaign.js'

  export default {
    name: 'RulesPanel',
    components: {
      Errors,
      RuleItem,
      CBtn
    },
    props: {
      campaignId: {
        type: Number,
        required: true
      },
      campaign: {
        type: Campaign,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        isEditing: false,
        rulesAreLoading: false,
        isCurrentEditingRuleId: 0,
        isCreating: false,
        errors: {},
        currentRule: new Rule()
      }
    },
    computed: {
      ...mapState('settings', [
        'maxCampaignUpdatingRuleConditions',
        'campaignUpdatingRuleTypes',
        'campaignUpdatingRuleConditionFields',
        'campaignUpdatingRuleOperations'
      ]),
      rules() {
        return this.campaign.updatingRules
      },
      rulesDefaultOptions() {
        return {
          action: this.campaignUpdatingRuleTypes[0],
          isActive: true,
          period: null,
          rule: {
            field: this.campaignUpdatingRuleConditionFields[0],
            operation: this.campaignUpdatingRuleOperations[0],
            value: null
          }
        }
      },
      errorMessages() {
        return Object.values(this.errors).flat()
      },
      canAddingRule() {
        return this.rules.length < this.maxCampaignUpdatingRuleConditions
      }
    },
    watch: {
      isLoading: {
        handler(newVal, oldVal) {
          if (!newVal && oldVal && !this.rules.length) {
            this.addRule()
          }
        }
      }
    },
    methods: {
      async save() {
        this.rulesAreLoading = true
        try {
          const transformData = Rule.transformRequest(this.currentRule)
          if (this.isCreating) {
            await campaignsRepository.addRule(this.campaignId, transformData)
          } else {
            await campaignsRepository.updateRule(this.campaignId, this.currentRule.id, transformData)
          }
          this.$emit('fetch-rules')
          this.isEditing = false
          this.isCurrentEditingRuleId = null
          this.isCreating = false
          this.currentRule = new Rule()
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.rulesAreLoading = false
      },
      cancel() {
        this.errors = {}
        this.isEditing = false
        this.isCurrentEditingRuleId = null
        this.isCreating = false
        this.$emit('fetch-rules')
      },
      addRule() {
        this.isCreating = true
        this.currentRule.updateRule(this.createRule())
      },
      clearError(error) {
        this.errors[error] = null
      },
      async deleteRule(ruleId) {
        this.rulesAreLoading = true
        try {
          await campaignsRepository.deleteRule(this.campaignId, ruleId)
          this.$emit('fetch-rules')
          this.isEditing = false
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.rulesAreLoading = false
      },
      createRule() {
        return {
          action: this.rulesDefaultOptions.action,
          isActive: this.rulesDefaultOptions.isActive,
          periodDays: this.rulesDefaultOptions.period,
          conditions: [{
            field: this.rulesDefaultOptions.rule.field,
            operation: this.rulesDefaultOptions.rule.operation,
            value: this.rulesDefaultOptions.rule.value
          }]
        }
      },
      updateCurrentRule(ruleId) {
        this.isCurrentEditingRuleId = ruleId
        const foundedRule = this.rules.find((rule) => rule.id === ruleId)
        if (foundedRule) {
          this.currentRule = foundedRule
          this.isEditing = true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.separator-item {
  border-top: 1px solid var(--v-secondary-base) !important;
}
</style>
